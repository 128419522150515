import React from "react";
import PropTypes from "prop-types";

export const HTMLContent = ({ content, className }) => {
  console.log({ content });
  const alteredContent = content
    .replace(/<h6>/gi, `<span class="bold">`)
    .replace(/<\/h6>/gi, "</span>");
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: alteredContent }}
    />
  );
};

const Content = ({ content, className }) => (
  <div className={className}>{content}</div>
);

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
};

HTMLContent.propTypes = Content.propTypes;

export default Content;
