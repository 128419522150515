import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const PathologieTemplate = ({
  content,
  contentComponent,
  name,
  emergency = false,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section>
      {helmet || ""}
      <h1>
        {name} {emergency && "(Urgence)"}
      </h1>
      <PostContent className="Markdown" content={content} />
    </section>
  );
};

PathologieTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  name: PropTypes.string,
  emergency: PropTypes.bool,
  bodypart: PropTypes.string,
  helmet: PropTypes.object,
};

const Pathologie = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter, html } = post || {};
  const { name, description, tags, emergency = false, bodypart } =
    frontmatter || {};

  return (
    <Layout>
      <section className="Layout__body">
        <PathologieTemplate
          content={html}
          contentComponent={HTMLContent}
          helmet={
            <Helmet titleTemplate="%s | Pathologie">
              <title>{name}</title>
              <meta name="description" content={description} />
            </Helmet>
          }
          tags={tags}
          name={name}
          bodypart={bodypart}
          emergency={emergency}
        />
      </section>
    </Layout>
  );
};

Pathologie.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Pathologie;

export const pageQuery = graphql`
  query PathologieByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        name
        bodypart
        emergency
        description
        tags
      }
    }
  }
`;
